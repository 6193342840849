<template>
    <v-container>
        <v-row class="tw-m-0 tw-p-0 mx-auto tw-max-w-5xl tw-py-3">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-container fluid class="tw-space-y-6 tw-pb-6">
                    <v-row>
                        <v-col cols="12" md="3" class="pt-5">
                            <FormDescription>
                                <template #title>
                                    {{ $t('admin.app.detail_gateway.title') }}
                                </template>
                                <template #copy>
                                    {{ $t('admin.app.detail_gateway.description') }}
                                </template>
                            </FormDescription>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-card class="tw-rounded-3xl tw-shadow-md tw-p-6 tw-mb-4">
                                <v-select
                                    v-model="gateway.name"
                                    :label="$t('admin.app.name_gateway')"
                                    :items="type_gateway"
                                    outlined
                                    rounded
                                    hide-details
                                />
                                <div class="tw-flex tw-justify-end lg:tw-mr-2">
                                    <v-checkbox v-model="gateway.is_active">
                                        <template #label>
                                            Habilita el uso de esta pasarela
                                        </template>
                                    </v-checkbox>
                                </div>
                            </v-card>
                            <div class="tw-flex tw-items-center tw-justify-end tw-mb-4">
                                <AppBtn icon="key" color="white" class="tw-shadow-md" @click="addKey">
                                    {{ $t('admin.app.add_key') }}
                                </AppBtn>
                            </div>
                            <v-card class="tw-rounded-3xl tw-shadow-md tw-p-6">
                                <template v-if="gateway.credentials && gateway.credentials.length > 0">
                                    <v-simple-table>
                                        <template #default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        {{ $t('admin.app.camps.key') }}
                                                    </th>
                                                    <th class="text-left">
                                                        {{ $t('admin.app.camps.value') }}
                                                    </th>
                                                    <th class="text-left">
                                                        {{ $t('admin.app.camps.action') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(field, index) in gateway.credentials" :key="index">
                                                    <td>
                                                        <v-text-field 
                                                            v-model="field.key" type="text" placeholder="key"
                                                            color="primary darken-1" solo single-line dense flat
                                                            hide-details />
                                                    </td>
                                                    <td>
                                                        <v-text-field 
                                                            v-model="field.value" type="text"
                                                            :placeholder="`value_${field.key}`" color="primary darken-1"
                                                            solo single-line dense flat hide-details />
                                                    </td>
                                                    <td>
                                                        <button 
                                                            type="button" class="tw-text-red-500"
                                                            @click="removeKey(index)">{{ $t('app.delete') }}</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                                <template v-else>
                                    <EmptyState>
                                        {{ $t('$vuetify.noDataText') }}
                                    </EmptyState>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import FormDescription from '@/components/FormDescription.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import EmptyState from '@/components/EmptyState.vue'
import { mapState } from 'vuex'
export default {
    name: 'GatewayFormBase',
    components: {
        FormDescription,
        AppBtn,
        EmptyState,
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        type_gateway() {
            return [
                { value: 'Niubiz', text: this.$t('admin.app.types_gateway.niubiz'), hidden: true },
                { value: 'Stripe', text: this.$t('admin.app.types_gateway.stripe'), hidden: true},
                { value: 'Izipay', text: this.$t('admin.app.types_gateway.izipay'), hidden: true},
                { value: 'Culqi', text: this.$t('admin.app.types_gateway.culqi')},
            ].filter((row) => !row.hidden)
        },
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        gateway: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            },
        },
    },
    methods: {
        addKey() {
            this.gateway.credentials.push({ key: '', value: '' })
        },
        removeKey(index) {
            this.gateway.credentials.splice(index, 1)
        },
        submitUpdate() {
            this.$store.dispatch('gateways/update', {
                payload: {
                    ...this.gateway,
                    registry_id: this.registry.id,
                },
            })
            .catch((error) => {
                console.error(error)
            })
        },
        submitCreate() {
            this.$store.dispatch('gateways/store', {
                payload: {
                    ...this.gateway,
                    registry_id: this.registry.id,
                },
            })
            .then(() => {
                this.$router.push({ name: 'admin.registries.gateways.index' })
            })
            .catch((error) => {
                console.error(error)
            })
        },
    },
}
</script>