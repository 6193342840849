export const BookFormBase = ({
    type_of_request = 'Reclamo',
    name = '',
    document_type = '',
    number_of_documents = '',
    // Representate de menor
    is_minor = false,
    name_representative = '',
    direction_representative = '',
    phone_representative = '',
    email_representative = '',
    // Representate de menor
    direction = '',
    department = '',
    province = '',
    district = '',
    email = '',
    repeat_email = '',
    phone = '',
    well_hired = 'Producto',
    well_hired_description = '',
    detail_complaint_description = '',
    actions_adopted = '',
}) => ({
    type_of_request,
    name,
    document_type,
    number_of_documents,
    // Representate de menor
    is_minor,
    name_representative,
    direction_representative,
    phone_representative,
    email_representative,
    // Representate de menor
    direction,
    department,
    province,
    district,
    email,
    repeat_email,
    phone,
    well_hired,
    well_hired_description,
    detail_complaint_description,
    actions_adopted,
})