export const GatewayBase = ({
    registry_id = '',
    name = '',
    credentials = [],
    is_active = true,
}) => ({
    registry_id,
    name,
    credentials,
    is_active,
})

export const GatewayBaseHydrate = ({
    id = '',
    registry_id = '',
    name = '',
    credentials = [],
    is_active = true,
    created_at = '',
    updated_at = '',
}) => ({
    id,
    registry_id,
    name,
    credentials,
    is_active,
    created_at,
    updated_at,
})