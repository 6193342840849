import $axios from '@/axios-instance'

export default class GatewaysService {
    baseUrl = '/api/v1/admin/registries'
    baseUrlGateway = '/api/v1/auth'

    static make() {
        return new GatewaysService()
    }

    index(registry, params) {
        return $axios.get(`${this.baseUrl}/${registry}/gateways`, params)
    }

    store(registry, params) {
        return $axios.post(`${this.baseUrl}/${registry}/gateways`, params)
    }

    show(registry, id, params) {
        return $axios.get(`${this.baseUrl}/${registry}/gateways/${id}`, params)
    }

    update(registry, id, params) {
        return $axios.post(`${this.baseUrl}/${registry}/gateways/${id}`, {
            ...params,
            _method: 'PUT',
        })
    }

    destroy(registry, id, params) {
        return $axios.post(`${this.baseUrl}/${registry}/gateways/${id}`, {
          ...params,
          _method: 'DELETE',
        })
    }

    // **************************************
    // ~ Paids
    // **************************************
    checkAttendee(params) {
        return $axios.post(`${this.baseUrlGateway}/check/attendee`, params)
    }
    createChargeCulqi(params) {
        return $axios.post(`${this.baseUrlGateway}/culqi/charge`, params)
    }

    // **************************************
    // ~ Book
    // **************************************
    sendBook(params) {
        return $axios.post(`${this.baseUrlGateway}/send/book`, params)
    }
}

export const GatewaysServiceInstance = new GatewaysService()