<template>
    <div>
        <v-dialog
            v-model="open_modal"
            scrollable
            max-width="400"
            content-class="tw-rounded-3xl tw-w-full"
        >
            <v-card class="tw-rounded-3xl tw-border-0">
                <v-card-text class="tw-bg-gray-100 tw-py-0">
                    <DropzonePreview
                        v-model="url_image_product"
                        :width="300"
                        :bypass="'simple-check'"
                        :height="150"
                        media-group="register"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <AppBtn type="button" color="transparent" @click="close">
                        {{ $t('app.cancel') }}
                    </AppBtn>
                    <AppBtn type="button" color="primary" icon="check" @click="save">
                        {{ $t('app.save') }}
                    </AppBtn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <AppBtn 
            type="button" 
            color="transparent-primary" 
            icon-center="imgSlider" 
            fab 
            small 
            @click="open_modal = true" 
        />
    </div>
</template>
<script>
import DropzonePreview from '@/components/DropzonePreview'
import AppBtn from '@/components/App/AppBtn.vue'

export default {
    components: {
        DropzonePreview,
        AppBtn,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        open_modal: false,
    }),
    computed: {
        url_image_product: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    methods: {
        close() {
          this.open_modal = false
        },
        save() {
            this.open_modal = false
        },
    },
}
</script>