<template>
    <renderless-layout>
        <v-container fluid class="tw-p-0">
            <sticky-header :nav="nav">
                <template #actions>
                    <AppBtn icon="plus" :to="{ name: 'admin.registries.gateways.create' }" color="white">
                        {{ $t('admin.app.new_gateway') }}
                    </AppBtn>
                </template>
            </sticky-header>
            <v-row class="tw-m-0 tw-p-0">
                <v-col cols="12" sm="12" md="12" lg="11" xl="9" class="mx-auto tw-max-w-5xl">
                    <v-container fluid class="tw-space-y-6 tw-py-8">
                        <v-row>
                            <template v-if="rows.length > 0">
                                <div class="tw-overflow-hidden tw-bg-white tw-shadow sm:tw-rounded-3xl tw-w-full">
                                    <ul role="list" class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                                        <li v-for="row in formatRows" :key="row.id">
                                            <button 
                                                v-ripple class="tw-block tw-w-full hover:tw-bg-gray-50"
                                                @click="updateGateway(row)">
                                                <div class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6">
                                                    <div
                                                        class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between">
                                                        <div class="tw-truncate">
                                                            <div class="tw-flex tw-text-sm">
                                                                <p
                                                                    class="tw-ml-0 tw-mb-0 tw-flex-shrink-0 tw-font-medium tw-text-gray-500">
                                                                    Pasarela:
                                                                </p>
                                                                <p
                                                                    class="tw-ml-1 tw-m-0 tw-truncate tw-font-normal tw-text-indigo-600">
                                                                    {{ row.name }}
                                                                </p>
                                                            </div>
                                                            <div class="tw-mt-2 tw-flex">
                                                                <div
                                                                    class="tw-flex tw-items-center tw-text-sm tw-text-gray-500">
                                                                    <!-- Heroicon name: solid/calendar -->
                                                                    <svg 
                                                                        class="tw-mr-1.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-text-gray-400"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 20 20" fill="currentColor"
                                                                        aria-hidden="true">
                                                                        <path 
                                                                            fill-rule="evenodd"
                                                                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                                            clip-rule="evenodd" />
                                                                    </svg>
                                                                    <p class="tw-mb-0">
                                                                        {{ $t('app.updated_at') }}
                                                                        <time :datetime="row.datetime">
                                                                            {{ row.date }}
                                                                        </time>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-else>
                                <div class="tw-w-full tw-justify-center tw-space-y-6">
                                    <button
                                        v-ripple
                                        type="button"
                                        class="tw-relative tw-block tw-w-full tw-rounded-lg tw-border-2 tw-border-dashed tw-border-gray-300 tw-p-8 tw-text-center tw-transition hover:tw-border-gray-400 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-500 focus:tw-ring-offset-2 lg:tw-py-40"
                                        @click="createGateway"
                                    >
                                        <GIcon class="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400" name="gateway"/>
                                        <span class="tw-mt-2 tw-block tw-text-sm tw-font-medium tw-text-gray-900">
                                            {{ $t('admin.app.new_gateway') }}
                                        </span>
                                    </button>
                                </div>
                            </template>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import GIcon from '@/components/GIcon.vue'

export default {
    name: 'GatewaysIndex',
    components: { StickyHeader, AppBtn, GIcon },
    data: () => ({
        loading: false,
        options: {},
        search: '',
        rows: [],
        totalRows: 0,
    }),
    computed: {
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.gateways'),
                    route: {},
                },
            ]
        },
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        formatRows() {
            return this.rows.map((row) => ({
                    ...row,
                    date: format(new Date(row.updated_at), "hh:ii, do 'de' MMMM yyyy", {
                        locale: es,
                    }),
                    datetime: format(new Date(row.updated_at), 'yyyy-MM-dd'),
                }))
        },
        hasMissingGateways() {
            return this.formatRows.length > 0 ? true : false
        },
    },
    watch: {
        search: {
            handler: debounce(function () {
                this.getGateways()
            }, 1000),
            deep: true,
        },
    },
    mounted() {
        this.getGateways()
    },
    methods: {
        createGateway() {
            return this.$router.push({
                name: 'admin.registries.gateways.create',
            })
        },
        updateGateway(gateway) {
            return this.$router.push({
                name: 'admin.registries.gateways.edit',
                params: { id: gateway.id },
            })
        },
        getGateways() {
            this.loading = true

            const { sortBy, sortDesc, page, itemsPerPage } = this.options

            return this.$store.dispatch('gateways/index', {
                params: {
                    page: page,
                    rowsPerPage: itemsPerPage,
                    sortBy: sortBy,
                    sortDesc: sortDesc,
                    search: this.search,
                    registry_id: this.registry.id,
                },
            })
                .then((response) => {
                    const { total, data } = response.data.gateways
                    this.rows = data
                    this.totalRows = total
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>