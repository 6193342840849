import { LanguageValues } from '@/enums/LanguageKeys'
import { dd_mm_yyyy, has_time_bracket, hh_mm } from '@/utils/dateHelpers'

export const RegistryFormBase = ({
  label = '',
  description = '',
  slug = '',
  start_date = '',
  language_key = 'es',
  start_time = '',
  end_date = '',
  end_time = '',
  time_bracket_tz = 'America/Lima',
  is_active = 1,
  has_time_bracket = false,

  has_password = true,
  can_export_connections = true,
  has_support = true,
  is_registration_disabled = false,
  support_email = '',
  support_phone_number = '',
  support_direction = '',
  support_user_id = '',
  is_only_active_for_default_lang = false,
  has_custom_fav_icon = false,
  custom_fav_icon_url = '',
  // Hybrid
  is_hybrid = false,
  hybrid_start_time = '',
  hybrid_end_time = '',
  hybrid_start_date = '',
  hybrid_end_date = '',
  hybrid_tz = '',
  hybrid_credential_image_url = '',
  // Multi Ticket
  is_multi_ticket = false,
  label_ticket = '',
  // Sending email with import excel
  is_sending_email = false,
  is_send_certificate = false,
  is_entry_or_add = false,
  is_add_contact_qr = false,
  is_autlogin_qr = false,
  // Has payment (Stripe)
  has_payment = false,
  publishable_key = '',
  private_key = '',
  pass_private_key = '',
  price_event = '',
  price_event_description = '',
  show_list_entries = false,
  disable_real_time = false,
}) => ({
  language_key,
  label,
  description,
  slug,
  start_date,
  start_time,
  end_date,
  end_time,
  has_time_bracket,
  time_bracket_tz,
  is_active,

  has_password,
  is_registration_disabled,
  can_export_connections,
  has_support,
  support_email,
  support_phone_number,
  support_direction,
  support_user_id,
  is_only_active_for_default_lang,
  has_custom_fav_icon,
  custom_fav_icon_url,
  // Hybrid
  is_hybrid,
  hybrid_start_time,
  hybrid_end_time,
  hybrid_start_date,
  hybrid_end_date,
  hybrid_tz,
  hybrid_credential_image_url,
  // Multi Ticket
  is_multi_ticket,
  label_ticket,
  // Sending email with import excel
  is_sending_email,
  is_send_certificate,
  is_entry_or_add,
  is_add_contact_qr,
  is_autlogin_qr,
  // Has payment (Stripe)
  has_payment,
  publishable_key,
  private_key,
  pass_private_key,
  price_event,
  price_event_description,
  show_list_entries,
  disable_real_time,
})
export const RegistryForm = () => RegistryFormBase({})
export const RegistryFormFromDB = ({
  label,
  description,
  slug,
  language_id,
  time_bracket_tz,
  start_date_time,
  end_date_time,
  has_password,
  can_export_connections,
  is_only_active_for_default_lang,
  has_support,
  is_registration_disabled,
  support_email,
  support_phone_number,
  support_direction,
  support_user_id,
  has_custom_fav_icon,
  custom_fav_icon_url,
  // Hybrid
  is_hybrid,
  hybrid_start_date_time,
  hybrid_end_date_time,
  hybrid_tz,
  hybrid_credential_image_url,
  // Multi Ticket
  is_multi_ticket,
  label_ticket,
  // Sending email with import excel
  is_sending_email,
  is_send_certificate,
  is_entry_or_add,
  is_add_contact_qr,
  is_autlogin_qr,
  // Has payment (Stripe)
  has_payment,
  publishable_key,
  private_key,
  pass_private_key,
  price_event,
  price_event_description,
  show_list_entries,
  disable_real_time,
}) =>
  RegistryFormBase({
    label,
    slug,
    description,
    language_key: LanguageValues[language_id],
    time_bracket_tz: time_bracket_tz,
    has_time_bracket: has_time_bracket(start_date_time, end_date_time),
    start_date: dd_mm_yyyy(start_date_time, time_bracket_tz),
    start_time: hh_mm(start_date_time, time_bracket_tz),
    end_date: dd_mm_yyyy(end_date_time, time_bracket_tz),
    end_time: hh_mm(end_date_time, time_bracket_tz),
    has_password,
    can_export_connections,
    is_only_active_for_default_lang,
    has_support,
    is_registration_disabled,
    support_email,
    support_phone_number,
    support_direction,
    support_user_id,
    has_custom_fav_icon,
    custom_fav_icon_url,
    // Hybrid
    is_hybrid,
    hybrid_start_date: dd_mm_yyyy(hybrid_start_date_time, hybrid_tz),
    hybrid_start_time: hh_mm(hybrid_start_date_time, hybrid_tz),
    hybrid_end_date: dd_mm_yyyy(hybrid_end_date_time, hybrid_tz),
    hybrid_end_time: hh_mm(hybrid_end_date_time, hybrid_tz),
    hybrid_tz,
    hybrid_credential_image_url,
    // Multi Ticket
    is_multi_ticket,
    label_ticket,
    // Sending email with import excel
    is_sending_email,
    is_send_certificate,
    is_entry_or_add,
    is_add_contact_qr,
    is_autlogin_qr,
    // Has payment (Stripe)
    has_payment,
    publishable_key,
    private_key,
    pass_private_key,
    price_event,
    price_event_description,
    show_list_entries,
    disable_real_time,
  })

export const RegistryObj = () => ({
  id: 0,
  label: '',
  description: '',
  slug: '',
  start_date_time: '',
  end_date_time: '',
  time_bracket_tz: '',
  is_default: 0,
  is_active: 1,
  created_at: '',
  updated_at: '',
  landings: [],
  forms: [],
  thank_yous: [],
  calendars: [],
  configs: [],
  access_control_strategy: {},
  credential: {},
  certificate: {},
})
