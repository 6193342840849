<template>
  <renderless-layout :full-screen="true">
    <RegistryLayout>
      <RegistryHeader :slug="slug" />
      <div class="tw-bg-landing-primary tw-py-12">
        <h1 class="bz-lbc tw-text-white tw-text-2xl lg:tw-text-5xl tw-text-center tw-font-bold">Libro de Reclamaciones</h1>
      </div>
      <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-4 md:tw-max-w-3xl lg:tw-px-8">
        <v-form ref="form_reclamo" v-model="valid" lazy-validation @submit.prevent="submitForm">
          <v-card class="mx-auto my-2 tw-shadow-none">
            <!-- Details -->
            <div class="tw-pt-8 tw-font-light tw-text-sm">
              <p>
                Conforme a lo establecido en el código de Protección y Defensa del consumidor contamos con un Libro de Reclamaciones a tu disposición. Ingresa una queja o reclamo aquí.
              </p>
              <p>
                (-) RECLAMO: Disconformidad relacionada a los productos expendidos o servicios brindados.
              </p>
              <p>
                (-) QUEJA: Disconformidad NO RELACIONADA a los productos o servicios. Malestar o descontento respecto con la atención al público.
              </p>
              <p class="tw-font-semibold">
                BASE LEGAL: LEY N° 29571 CÓDIGO DE PROTECCIÓN Y DEFENSA AL CONSUMIDOR. REGLAMENTO D.S. N° 011-2011-PCM Y SUS MODIFICATORIAS D.S. N° 037-2011-PCM Lgy
              </p>
            </div>
            <!-- Form -->
            <div class="tw-pt-4">
              <!-- Tipo de Solicitud -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="description"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  TIPO DE SOLICITUD: *
                </div>
                <div class="ml-6">
                  <v-radio-group v-model="form.type_of_request" row>
                    <v-radio label="Reclamo" value="Reclamo"/>
                    <v-radio label="Queja" value="Queja"/>
                  </v-radio-group>
                </div>
              </div>
              <!-- Datos Personales -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="person"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  DATOS PERSONALES:
                </div>
                <div class="ml-6">
                  <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                    <v-text-field
                      v-model="form.name"
                      label="Nombres y Apellidos"
                      :rules="[rules.required]"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-select
                      v-model="form.document_type"
                      label="Documento de Identidad"
                      :items="documentos_identidad"
                      :rules="[rules.required]"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-checkbox
                      v-model="form.is_minor"
                      label="Soy menor de edad"
                    />
                    <v-text-field
                      v-model="form.number_of_documents"
                      label="Número de Documento"
                      :rules="[rules.required]"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <template v-if="form.is_minor">
                      <div class="tw-col-span-2">
                        <p class="tw-text-secondary-400 tw-text-sm tw-font-semibold tw-mb-2">Necesitamos los datos de tu padre, madre o representante Legal.</p>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                          <v-text-field
                            v-model="form.name_representative"
                            label="Nombre"
                            :rules="[rules.required]"
                            outlined
                            rounded
                            hide-details="auto"
                          />
                          <v-text-field
                            v-model="form.direction_representative"
                            label="Direcciœn"
                            outlined
                            rounded
                            hide-details="auto"
                          />
                          <v-text-field
                            v-model="form.phone_representative"
                            label="Teléfono"
                            outlined
                            rounded
                            hide-details="auto"
                          />
                          <v-text-field
                            v-model="form.email_representative"
                            label="Email"
                            :rules="[rules.required, rules.email]"
                            outlined
                            rounded
                            hide-details="auto"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!-- Datos de ubicacion y contacto -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="place"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  DATOS DE UBICACION Y CONTACTO:
                </div>
                <div class="ml-6">
                  <div class="tw-grid tw-grid-cols-2 tw-gap-4">
                    <v-text-field
                      v-model="form.direction"
                      label="Dirección"
                      outlined
                      rounded
                      class="tw-col-span-2"
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.department"
                      label="Departamento"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.province"
                      label="Provincia"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.district"
                      label="Distrito"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.email"
                      label="E-mail"
                      :rules="[rules.required, rules.email]"
                      outlined
                      rounded
                      hint="*Este campo es obligatorio, te enviaremos la constancia de la presentación de tu reclamo a este E-mail"
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.phone"
                      label="Teléfono"
                      outlined
                      rounded
                      hide-details="auto"
                    />
                    <v-text-field
                      v-model="form.repeat_email"
                      label="Repetir E-mail"
                      :rules="[rules.required, rules.email]"
                      outlined
                      rounded
                      hint="*Este campo es obligatorio. Por motivos de seguridad, debe volver a ingresar el E-mail."
                      hide-details="auto"
                    />
                  </div>
                </div>
              </div>
              <!-- Identificacion del bien contratado -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="description"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  IDENTIFICACIÓN DEL BIEN CONTRATADO
                </div>
                <div class="ml-6">
                  <v-radio-group v-model="form.well_hired" row>
                    <v-radio label="Producto" value="Producto"/>
                    <v-radio label="Servicio" value="Servicio"/>
                  </v-radio-group>
                  <v-textarea
                    v-model="form.well_hired_description"
                    name="well_hired_description"
                    label="Descripción"
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                </div>
              </div>
              <!-- Detalle de reclamacion -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="description"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  DETALLE DE LA RECLAMACIÓN
                </div>
                <div class="ml-6">
                  <v-textarea
                    v-model="form.detail_complaint_description"
                    name="detail_complaint_description"
                    label="Descripción"
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                </div>
              </div>
              <!-- Acciones adoptadas por el vendedor -->
              <div class="tw-mb-6">
                <div class="tw-text-primary-500 tw-font-semibold tw-flex tw-items-center tw-mb-6">
                  <g-icon
                    name="mdIcon"
                    md-icon="description"
                    class="tw-mr-2 tw-text-primary-500"
                  />
                  ACCIONES ADOPTADAS POR EL VENDEDOR
                </div>
                <div class="ml-6">
                  <v-textarea
                    v-model="form.actions_adopted"
                    name="actions_adopted"
                    label="Descripción"
                    :rules="[rules.required]"
                    outlined
                    rounded
                  />
                </div>
              </div>
            </div>
            <!-- Action -->
             <div class="tw-flex tw-items-center tw-justify-end tw-mb-6">
              <AppBtn type="submit" color="primary" :loading="loading" icon="check">
                Enviar
              </AppBtn>
             </div>
          </v-card>
        </v-form>
        <v-snackbar v-model="isAlert" :color="alertColor">{{ msgAlert }}</v-snackbar>
      </div>
    </RegistryLayout>
  </renderless-layout>
</template>
<script>
import RegistryLayout from './RegistryLayout.vue'
import RegistryHeader from './RegistryHeader.vue'
import GIcon from '@/components/GIcon.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { rules } from '@/utils/rules'
import { BookFormBase } from '@/utils/Book'

export default {
  name: 'LibroDeReclamaciones',
  components: {
    RegistryLayout,
    RegistryHeader,
    GIcon,
    AppBtn,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    valid: true,
    loading: false,
    rules,
    form: BookFormBase({}),
    isAlert: false,
    alertColor: '',
    msgAlert: '',
  }), 
  computed: {
    documentos_identidad() {
      return [
        { value: 'DNI', text: 'DNI' },
        { value: 'RUC', text: 'RUC' },
        { value: 'CE', text: 'CE' },
        { value: 'Pasaporte', text: 'Pasaporte' },
      ]
    },
  },
  methods: {
    submitForm() {
      this.loading = true

      const payload = {
        form_submission: {
          ...this.form,
        },
      }

      if (!this.$refs.form_reclamo.validate()) {
        this.loading = false
        return
      }

      this.$store.dispatch('gateways/sendNewBook', payload)
        .then(({ data }) => {
          console.log('res book', data.message)
          this.isAlert = true
          this.alertColor = 'green'
          this.msgAlert = data.message
          this.form = BookFormBase({})
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>