<template>
    <renderless-layout>
        <v-container id="gateways_index_page" fluid class="tw-p-0">
            <StickyHeader :nav="nav">
                <template #actions>
                    <div class="tw-space-x-3">
                        <AppBtn icon="check" color="white" @click="createGateway">
                            {{ $t('app.save') }}
                        </AppBtn>
                    </div>
                </template>
            </StickyHeader>
            <FormBase
                ref="gatewayform"
                v-model="gateway"
            />
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState } from 'vuex'
import FormBase from '@/views/Admin/Registries/Gateways/FormBase.vue'
import { GatewayBase } from '@/models/Gateway'
export default {
    name: 'GatewaysCreate',
    components: {StickyHeader,AppBtn, FormBase},
    data: () => ({
        gateway: GatewayBase({}),
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
        }),
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.gateways'),
                    route: {
                        name: 'admin.registries.gateways.index',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('app.create'),
                    route: {},
                },
            ]
        },
    },
    methods: {
        createGateway() {
            this.$refs.gatewayform.submitCreate()
        },
    },
}
</script>