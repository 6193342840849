import { GatewaysServiceInstance } from '@/services/GatewaysService'
import { formErrorsHandler } from '@/utils/formErrorsHandler'

export const Gateways = {
    namespaced: true,
    state: {
        show: {
            data: {},
            loading: false,
        },
    },
    mutations: {
        setData(state, obj) {
            Object.keys(obj).forEach((k) => (state.show.data[k] = obj[k]))
        },
        setShowLoading(state, bool) {
          state.show.loading = bool
        },
    },
    actions: {
        // *********************************************** //
        // ~ Dliza | Gateway index, store, update, delete
        // *********************************************** //
        index(__, payload) {
            const { registry_id } = payload.params
            return GatewaysServiceInstance.index(registry_id, payload)
            .then((response) => response)
            .catch((error) => {
                throw error
            })
        },
        store({ commit }, { payload }) {
            commit('setAppIsLoading', true, { root: true })
            const { registry_id } = payload
            return GatewaysServiceInstance.store(registry_id, payload)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        update({ commit }, { payload }) {
            commit('setAppIsLoading', true, { root: true })
            return GatewaysServiceInstance.update( payload.registry_id, payload.id, payload)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        show({ commit }, { id, registry_id }) {
            commit('setShowLoading', true)
            return GatewaysServiceInstance.show(registry_id, id)
                .then(({ data }) => {
                    const { gateway } = data
                    commit('setData', {
                        ...gateway,
                        credentials: Array.isArray(gateway.credentials) ? gateway.credentials : [],
                    })
                    return gateway
                }) 
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setShowLoading', false)
                })
        },
        destroy({ commit }, { id = '', registry_id = '' }) {
            commit('setAppIsLoading', true, { root: true })
            return GatewaysServiceInstance.destroy(registry_id, id)
                .then((res) => res)
                .catch((err) => {
                    throw formErrorsHandler(err)
                })
                .finally(() => {
                    commit('setAppIsLoading', false, { root: true })
                })
        },
        checkAttendee(__, payload) {
            return GatewaysServiceInstance.checkAttendee(payload)
                .then((res) => {
                    return res
                })
                .catch((error) => {
                    throw formErrorsHandler(error)
                })
        },
        culqiCharge(__, payload) {
            return GatewaysServiceInstance.createChargeCulqi(payload)
                .then((res) => {
                    return res
                })
                .catch((error) => {
                    throw formErrorsHandler(error)
                })
        },
        sendNewBook(__, payload) {
            console.log('payload book', payload)
            return GatewaysServiceInstance.sendBook(payload)
                .then((res) => {
                    return res
                })
                .catch((error) => {
                    throw formErrorsHandler(error)
                })
        },
    },
}