import { short_uid } from '@/helpers'
import { arrayToObject } from '@/utils/arrayToObject'
export const FieldTypes = () => ({
  fields: [
    {
      text: 'Texto',
      value: 'input',
      icon: 'text_fields',
    },
    {
      text: 'Texto Largo',
      value: 'textarea',
      icon: 'notes',
    },
    {
      text: 'Radio',
      value: 'radio',
      icon: 'radio_button_checked',
    },
    {
      text: 'Checkbox',
      value: 'checkbox',
      icon: 'check_box',
    },
    {
      text: 'Select',
      value: 'select',
      icon: 'list',
    },
    {
      text: 'Date',
      value: 'date',
      icon: 'calendar_today',
    },
    {
      text: 'Number',
      value: 'number',
      icon: 'dialpad',
    },
  ],
})
export const LookUpFields = arrayToObject(FieldTypes().fields, 'value')
/**
 * Creates form element .
 * @example
 * label: "email",
 * name: "email",
 * rules: ["required"],
 * messages: {
 *   required: "Email es necesario",
 * },
 * options: [
 *   {
 *     text: "",
 *     value: "",
 *   },
 *  ],
 * });
 *
 */
export const RegistryRegisterInputBase = ({
  label = '',
  model = '',
  model_multiple = [],
  name = '',
  field_type = 'input',
  rules = [],
  cols = 12,
  is_active = false,
  is_required = false,
  is_public = false,
  messages = {},
  options = [],
  uid = short_uid(),
}) => ({
  label,
  model,
  model_multiple,
  name,
  field_type,
  rules,
  cols,
  messages,
  options,
  uid,
  is_active,
  is_required,
  is_public,
})

export const FormOption = ({ text = '', value = '', uid = short_uid() }) => ({
  text,
  value,
  uid,
})

export const RegistryRegisterDefaultField = ({
  label = '',
  name = '',
  is_active = false,
  is_required = false,
  is_public = false,
}) => ({
  label,
  name,
  is_active,
  is_required,
  is_public,
})

export const RegistryRegisterForm = ({
  label = '',
  description = '',
  default_fields = [],
  fields = [],
  header_url = '',
  form_heading_label = '',
  form_heading_terms = '',
  terms_and_conditions_label = '',
  terms_and_conditions_link = '',
  exchange_refund_policies_link = '',
  terms_and_conditions_company = '',
}) => ({
  label,
  header_url,
  description,
  default_fields,
  fields,
  form_heading_label,
  form_heading_terms,
  terms_and_conditions_label,
  terms_and_conditions_link,
  exchange_refund_policies_link,
  terms_and_conditions_company,
})
