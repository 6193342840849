<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <StickyHeader :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <AppBtn v-if="false" icon="trash_bin" color="transparent-dark">
              {{ $t('app.delete') }}
            </AppBtn>
            <AppBtn
              icon="lock"
              color="transparent-dark"
              :to="{
                name: 'admin.configs.access-control',
                params: { id: show.id },
              }"
            >
              {{ $t('admin.events.items.labels.access_control') }}
            </AppBtn>
            <AppBtn
              icon="pencil_edit"
              color="white"
              @click="registries_settings_modal = true"
            >
              {{ $t('admin.events.edit_event') }}
            </AppBtn>
          </div>
        </template>
      </StickyHeader>
      <v-row class="tw-m-0 tw-p-0">
        <v-col cols="12" sm="12" md="12" lg="7" class="mx-auto tw-mb-8">
          <!-- Actions panel -->
          <div class="tw-container tw-mx-auto tw-py-10">
            <section aria-labelledby="quick-links-title ">
              <div
                class="tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-bg-gray-200 tw-shadow sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px sm:tw-divide-y-0"
              >
                <div class="tw-sr-only">
                  <primary-title>Quick links</primary-title>
                </div>
                <home-quick-link
                  v-for="(link, index) in quick_links"
                  :key="link.name"
                  :index-item="index"
                  :length-items="quick_links.length"
                  :name="link.name"
                  :details="link.details"
                  :marker-type="link.markerType"
                  :md-icon="link.mdIcon"
                  :g-next="link.gNext"
                  :icon="link.icon"
                  :route-to="link.route"
                  item-type="router-link"
                />
              </div>
            </section>
          </div>
          <div class="tw-container tw-mx-auto tw-py-10">
            <section aria-labelledby="quick-links-title ">
              <div
                class="tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-3xl tw-bg-gray-200 tw-shadow sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-px sm:tw-divide-y-0"
              >
                <div class="tw-sr-only">
                  <primary-title>Quick links</primary-title>
                </div>
                <home-quick-link
                  v-for="(link, index) in platform_links"
                  :key="link.name"
                  :index-item="index"
                  :length-items="platform_links.length"
                  :name="link.name"
                  :details="link.details"
                  :marker-type="link.markerType"
                  :md-icon="link.mdIcon"
                  :g-next="link.gNext"
                  :icon="link.icon"
                  :route-to="link.route"
                  item-type="router-link"
                />
              </div>
            </section>
          </div>
          <registries-settings-modal
            v-if="registries_settings_modal"
            v-model="registries_settings_modal"
            :settings="show"
          />
        </v-col>
      </v-row>
    </v-container>
  </renderless-layout>
</template>

<script>
import RegistriesSettingsModal from '@/components/RegistriesModals/RegistriesSettingsModal.vue'
import HomeQuickLink from '@/components/HomeQuickLink.vue'
import PrimaryTitle from '@/components/Titles/PrimaryTitle.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import { mapState } from 'vuex'
import AppBtn from '@/components/App/AppBtn.vue'
const event_links = [
  {
    route: 'general',
    name: 'Plataforma',

    label: 'platform',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',

    markerType: 'svg',
    icon: 'g-next',
    gNext: 'Villa',
  },
  {
    route: 'leaderboards',
    name: 'Leaderboard',
    label: 'leaderboards',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',

    markerType: 'svg',
    icon: 'award',
  },
  {
    route: 'event-broadcasts',
    name: 'Notificaciones',
    label: 'notifications',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',

    markerType: 'svg',
    icon: 'bell',
  },

  {
    route: 'photo-booth',
    name: 'Photo Booth',
    label: 'photobooth',
    details:
      ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',

    markerType: 'svg',
    icon: 'g-next',
    gNext: 'Photo',
  },
]
export default {
  components: {
    RegistriesSettingsModal,
    PrimaryTitle,
    StickyHeader,
    HomeQuickLink,
    AppBtn,
  },
  data: () => ({
    registries_settings_modal: false,
  }),
  computed: {
    links() {
      return [
        {
          route: 'forms',
          name: 'Formulario de Registro',
          label: 'forms',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          icon: require('@/assets/registries-icons/form.svg'),
        },
        {
          route: 'custom-forms',
          name: 'Custom Forms',
          label: 'custom_forms',
          details: '',
          icon: require('@/assets/registries-icons/form.svg'),
        },
        {
          route: 'participants',
          name: 'Asistentes',
          label: 'attendees',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          markerType: 'svg',
          icon: 'users_group',
        },
        {
          route: 'thank-yous',
          name: 'Página de Agradecimiento',
          label: 'thank_yous',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          icon: require('@/assets/registries-icons/logout.svg'),
        },
        {
          route: 'landings',
          name: 'Landing Page',
          label: 'landings',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          icon: require('@/assets/registries-icons/coding.svg'),
        },
        {
          route: 'login-screens',
          name: 'Inicio de Sesión',
          label: 'logins',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          icon: require('@/assets/registries-icons/enter.svg'),
        },
        {
          route: 'confirm-screens',
          name: 'Página de Confirmación',
          label: 'confirms',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          markerType: 'svg',
          icon: 'check',
        },
        {
          route: 'calendar',
          name: 'Calendario',
          label: 'calendars',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          markerType: 'svg',
          icon: 'calendar',
        },
        {
          route: 'emails',
          name: 'Emails',
          label: 'email',
          details:
            ' Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis et quo et molestiae. ',
          markerType: 'svg',
          icon: 'email',
        },
        {
          route: 'certificates',
          name: 'Certificados',
          label: 'certificates',
          details: '',
          markerType: 'svg',
          icon: 'certificate',
          hidden: !this.show.is_send_certificate,
        },
        {
          route: 'credentials',
          name: 'Credenciales',
          label: 'credentials',
          details: '',
          markerType: 'svg',
          icon: 'credential',
          hidden: !this.show.is_hybrid,
        },
        {
          route: 'gateways',
          name: 'Pasarela de pago',
          label: 'gateways',
          details: '',
          markerType: 'svg',
          icon: 'gateway',
          hidden: !this.show.has_payment,
        },
      ].filter((row) => !row.hidden)
    },
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
    nav() {
      return [
        {
          name: this.$t('admin.home.labels.events'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {},
        },
      ]
    },
    quick_links() {
      return this.links.map((row) => ({
        ...row,
        name: this.$t(`admin.events.items.labels.${row.label}`),
        details: this.$t(`admin.events.items.description.${row.label}`),
        route: `/admin/registries/${this.show.id}/${row.route}`,
      }))
    },
    platform_links() {
      return event_links
        .map((row) => ({
          ...row,
          name: row.route,
          route: `/admin/registries/${this.show.id}/${row.route}`,
        }))
        .filter((link) => {
          return link.name === 'general'
            ? this.license.can_concurrent_events
            : true
        })
        .map((link) => ({
          ...link,
          name: this.$t(`admin.events.items.labels.${link.label}`),
          details: this.$t(`admin.events.items.description.${link.label}`),
        }))
    },
    ...mapState({
      license: (state) => state.constants.data.license,
      show: (state) => state.registries.show.data,
    }),
  },
}
</script>

<style></style>
