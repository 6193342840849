<script>
import { mapState } from 'vuex'

export default {
    name: 'PaymentComponent',
    props: {
        name: {
            type: String,
            default: 'Culqi',
        },
        data: {
            type: [Array, Object],
            default: () => ({}),
        },
    },
    data: () => ({
        culqi: null,
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registry.show.data,
        }),
    },
    methods: {
        initCulqiPayment() {
            if (!this.culqi) {
                this.initCulqi()
            }
            
            this.culqi.open()
        },
        initCulqi() {
            this.culqi = new window.CulqiCheckout(this.data.publicKey, this.data.config)

            this.culqi.culqi = this.createCharge
        },
        createCharge() {
            if (this.culqi && this.culqi.token) {
                const token = this.culqi.token.id
                const details = this.data.config.settings
                const userRegister = this.data.userRegister
                const payload = {
                    registry_id: this.registry.id,
                    amount: details.amount / 100,
                    currency: details.currency,
                    title: details.title,
                    name: userRegister.name,
                    email: userRegister.email,
                    token: token,
                }
                console.log('✅ Culqi ha respondido con una transacción.', payload)
                this.$store.dispatch('gateways/culqiCharge', payload)
                    .then((res) => {
                        console.log('respuesta de cargo', res)
                        if (res.data.success) {
                            this.culqi.close()
                            this.$emit('on-success')
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        },
    },
}
</script>

<template>
    <div class="payment-component">
        <template v-if="name === 'Culqi'">
            <button 
              type="button" 
              class="flex items-center tw-justify-center tw-w-full tw-px-4 tw-py-2 tw-bg-gray-100 tw-text-white tw-rounded-lg hover:tw-bg-gray-200 transition tw-shadow-sm"
              @click="initCulqiPayment"
            >
              <img :src="require('@/assets/culqi.png')" alt="culqi" class="tw-h-7 tw-mx-auto"/>
            </button>
        </template>
    </div>
</template>