import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import store from '@/store'
// import Home from '../views/Home.vue'

import CommonContainer from '@/components/CommonContainer.vue'

import AdminRoutes from '@/router/admin.router.js'
import AuthRoutes from '@/router/auth.router.js'
// registry global routes
import RegistryContainer from '@/views/Registry/RegistryContainer'
import RegistryAllForms from '@/views/Registry/RegistryAllForms.vue'
import RegistryHome from '@/views/Registry/RegistryHome.vue'
import RegistryLogin from '@/views/Registry/RegistryLogin.vue'
import RegistryRegister from '@/views/Registry/RegistryRegister.vue'
import RegistryForgotPassword from '@/views/Registry/RegistryForgotPassword.vue'
import RegistryResetPassword from '@/views/Registry/RegistryResetPassword'
import RegistryComplaintsBook from '@/views/Registry/RegistryComplaintsBook.vue'

// event
import EventVirtualRoom from '@/views/Event/EventVirtualRoom.vue'
import EventContainer from '@/views/Event/EventContainer.vue'

import NotAuthorized from '@/views/Static/NotAuthorized.vue'
import NotFound from '@/views/Static/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'registry.home.index',
    },
    // component: Home,
    // meta: {
    //   requiresAuth: true,
    //   requiresAdmin: true,
    // },
  },
  // TODO: Refactor to clasify admin routes, Business routes
  ...AdminRoutes,
  {
    path: '/registry',
    component: CommonContainer,
    meta: {
      // requiresGuest: true,
    },
    children: [
      {
        path: '',
        name: 'registry.home.index',
        component: RegistryAllForms,
        // redirect: {
        //   name: 'registry.home',
        //   params: { slug: 'home' },
        // },
      },
      {
        path: 'rewrite',
        name: 'registry.home.rewrite',
        redirect: {
          name: 'registry.home',
          params: { slug: 'home' },
        },
      },
      {
        path: ':slug',
        component: RegistryContainer,
        props: true,
        children: [
          {
            path: '',
            name: 'registry.home',
            component: RegistryHome,
            props: true,
          },
          {
            path: 'register',
            name: 'registry.register',
            component: RegistryRegister,
            props: true,
          },
          {
            path: 'login',
            name: 'registry.login',
            component: RegistryLogin,
            props: true,
          },
          {
            path: 'forgot-password',
            name: 'registry.forgot-password',
            component: RegistryForgotPassword,
            props: true,
          },
          {
            path: 'reset-password/:token/:email',
            name: 'registry.reset-password',
            component: RegistryResetPassword,
            props: true,
          },
          {
            path: 'thank-you',
            name: 'registry.thank-you',
            // component: RegistryThankYou,
            component: () =>
              import(
                /* webpackChunkName: "registry-thank-you-page" */
                '@/views/Registry/RegistryThankYou.vue'
              ),
            props: true,
          },
          {
            path: 'libro-de-reclamaciones',
            name: 'registry.complaints-book',
            component: RegistryComplaintsBook,
            props: true,
          },
          {
            path: 'confirmed',
            name: 'registry.confirmed',

            component: () =>
              import(
                /* webpackChunkName: "registry-confirmed-page" */
                '@/views/Registry/RegistryConfirmed.vue'
              ),
            props: true,
          },
          {
            path: 'magic/:uuid/:user',
            name: 'registry.magic',
            component: () => import('@/views/Registry/RegistryLoginMagic.vue'),
            props: true,
          },
        ],
      },
    ],
  },
  // {
  //   path: '/event',
  //   component: CommonContainer,
  //   meta: {
  //     requiresAuth: true,
  //   },
  //   children: [
  //     {
  //       path: '/',
  //       name: 'event.home.rewrite',
  //       redirect: { name: 'event.route', params: { slug: 'home' } },
  //     },
  //     {
  //       path: ':slug',
  //       component: EventContainer,
  //       props: true,
  //       children: [
  //         {
  //           path: '',
  //           name: 'event.route',
  //           component: EventVirtualRoom,
  //           props: true,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // /event/{event}/{sala}
  {
    path: '/event/:eventSlug',
    component: CommonContainer,
    meta: {
      requiresAuth: true,
    },
    props: true,
    children: [
      {
        path: '/',
        name: 'event.home.rewrite',
        props: true,
        redirect: (to) => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return {
            name: 'event.route',
            params: { eventSlug: to.params.eventSlug, slug: 'home' },
          }
        },
      },
      {
        path: ':slug',
        component: EventContainer,
        props: true,
        children: [
          {
            path: '',
            name: 'event.route',
            component: EventVirtualRoom,
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: '/event-preview',
    component: CommonContainer,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'event-preview.home.rewrite',
        redirect: { name: 'event-preview.route', params: { slug: 'home' } },
      },
      {
        path: ':slug',
        component: EventContainer,
        props: true,
        children: [
          {
            path: '',
            name: 'event-preview.route',
            component: EventVirtualRoom,
            props: true,
          },
        ],
      },
    ],
  },
  ...AuthRoutes,
  { path: '/403', component: NotAuthorized, name: 'not_authorized' },
  { path: '/404', component: NotFound, name: 'not_found' },
  { path: '*', redirect: { name: 'not_found' } },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  store
    .dispatch('initApp')
    .then(() => {
      if (
        to.matched.some((record) => record.meta.requiresAuth) &&
        !store.state.session.active
      ) {
        if (to.matched.some((record) => record.meta.requiresAdmin)) {
          next({ name: 'auth.login' })
          return
        }
        store.commit('registry/setPath',{link:to.path, params: to.params})
        // next({ name: 'registry.login', params: { slug: 'home' } })
        // enviar el to a un state
        // en el login si todo bien enviar a esa ruta original
        // si el usuario se va de ese login se deberia perder el state 
        next({
          name: 'registry.login',
          params: { slug: to.params.eventSlug },
        })
        // next({ name: 'registry.home.index' })
        return
      }

      if (store.state.session.active) {
        if (to.matched.some((record) => record.meta.requiresAdmin)) {
          // if (store.state.session.user.role.name != "admin") {
          if (
            !store.state.session.user.roles
              .map((row) => row.name)
              .includes('admin')
          ) {
            next({ name: 'not_authorized' })
            return
          }
        }
        if (to.matched.some((record) => record.meta.requiresGuest)) {
          // if (store.state.session.user.role.name == "admin") {
          if (
            store.state.session.user.roles
              .map((row) => row.name)
              .includes('admin')
          ) {
            next({ name: 'admin.dashboard' })
            return
          }
          next({ name: 'event.route', params: { slug: 'home' } })
          return
        }
        // next("/");
        // return;
      }
      next()
      return
    })
    .catch((err) => {
      console.error(err)
    })
})

export default router
