export const RegistryTicketBase = ({
    ticket_label_key = '',
    registry_id = '',
    virtual_room_id = '',
}) => ({
    ticket_label_key,
    registry_id,
    virtual_room_id,
})

export const RegistryTicketHydrate = ({
    ticket_label_key,
    registry_id,
    virtual_room_id,
}) => ({
    ticket_label_key,
    registry_id,
    virtual_room_id,
})

export const RegistryTicketData = ({
    ticket_label_key = '',
    registry_id = '',
    order = '',
    price_ticket = '',
    ticket_description = '',
    is_access_total = false,
    enterprise_label = '',
    url_image_product = '',
}) => ({
    ticket_label_key,
    registry_id ,
    order,
    price_ticket,
    ticket_description,
    is_access_total,
    enterprise_label,
    url_image_product,
})

export const RegistryTicketHydrateData = ({
    ticket_label_key,
    registry_id,
    order,
    price_ticket,
    ticket_description,
    is_access_total,
    enterprise_label,
    url_image_product,
}) => ({
    ticket_label_key,
    registry_id ,
    order,
    price_ticket,
    ticket_description,
    is_access_total,
    enterprise_label,
    url_image_product,
})