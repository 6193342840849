<template>
  <renderless-layout :full-screen="true">
    <RegistryLayout>
      <RegistryHeader :slug="slug" />
      <main>
        <!-- ************************************** -->
        <!-- ~ Register Background                  -->
        <!-- ************************************** -->
        <div class="tw-aspect-w-16 tw-aspect-h-5 tw-relative tw-bg-gray-900">
          <div class="tw-absolute tw-inset-0">
            <img aria-hidden="true" class="tw-h-full tw-w-full tw-object-cover" :src="bgUrl" />
            <div
              v-if="false"
              class="tw-absolute tw-inset-0 tw-bg-gradient-to-r tw-from-gray-900 tw-to-transparent"
              style="mix-blend-mode: multiply"
            />
          </div>
          <div v-if="current_form.label" class="tw-relative lg:tw-py-24 xl:tw-absolute">
            <div class="tw-mx-auto tw-max-w-7xl tw-pl-4 tw-pr-8 sm:tw-px-6 lg:tw-px-8">
              <h1 class="tw-text-4xl tw-font-extrabold tw-tracking-tight tw-text-slate-50 sm:tw-text-5xl lg:tw-text-6xl">
                {{ current_form.label }}
              </h1>
              <p class="tw-mt-6 tw-max-w-3xl tw-text-xl tw-text-slate-300">
                {{ current_form.description }}
              </p>
            </div>
          </div>
        </div>

        <!-- ************************************** -->
        <!-- ~ Register with payment                -->
        <!-- ************************************** -->
        <section v-if="registry.has_payment" class="tw-relative tw-bg-slate-100 tw-p-0 md:tw-py-12">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            :disabled="loading"
            @submit.prevent="handleSubmit"
          >
            <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-0 sm:tw-px-4 md:tw-max-w-6xl lg:tw-px-8" :class="registry.has_payment ? 'md:tw-max-w-6xl' : 'md:tw-max-w-5xl'">
              <v-row>
                  <!-- ************ User Information ************ -->
                  <v-col cols="12" sm="12" md="8">
                    <v-card class="mx-auto overflow-hidden tw-rounded-xl tw-shadow-md">
                      <v-container class="tw-px-6 tw-py-5">
                        <h2 class="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                          {{ $t('pages.registry.register.main_form_title') }}
                        </h2>
                      </v-container>
                      <v-divider class="tw-border-gray-100"/>
                      <v-container class="tw-px-6 tw-py-4">
                        <div class="tw-my-4">
                          <div class="tw-grid tw-gap-6 lg:tw-grid-cols-1">
                            <div>
                              <v-autocomplete
                                v-if="registry.is_multi_ticket"
                                v-model="ticket_select"
                                :items="registry.registry_tickets"
                                hide-no-data
                                hide-selected
                                item-text="ticket_label_key"
                                item-value="id"
                                :label="registry.label_ticket" 
                                return-object
                                outlined
                                rounded
                                validate-on-blur
                                :rules="[rules.required]"
                                class="tw-text-sm" 
                              />
                            </div>
                          </div>
                          <div class="tw-grid lg:tw-grid-cols-3">
                            <div class="lg:tw-col-span-3">
                              <v-text-field
                                ref="email"
                                v-model="form.email"
                                prepend-inner-icon="alternate_email"
                                :label="$t('forms.email')"
                                type="email"
                                outlined
                                validate-on-blur
                                :rules="[rules.required, rules.email]"
                                rounded
                              />
                            </div>
                          </div>
                          <div
                            v-if="registry.has_password"
                            class="tw-grid tw-gap-6 lg:tw-grid-cols-2"
                          >
                            <div class="">
                              <v-text-field
                                v-model="form.password"
                                :label="$t('forms.password')"
                                :rules="[rules.required]"
                                outlined
                                :append-icon="
                                  show_password ? 'visibility_off' : 'visibility'
                                "
                                :type="show_password ? 'text' : 'password'"
                                validate-on-blur
                                rounded
                                @click:append="show_password = !show_password"
                              />
                            </div>
                            <div>
                              <v-text-field
                                v-model="form.password_confirmation"
                                :label="$t('forms.confirm_password')"
                                :append-icon="
                                  show_password_confirmation
                                    ? 'visibility_off'
                                    : 'visibility'
                                "
                                :type="
                                  show_password_confirmation ? 'text' : 'password'
                                "
                                :rules="[rules.required, passwordMatch]"
                                outlined
                                validate-on-blur
                                required
                                rounded
                                @click:append="
                                  show_password_confirmation =
                                    !show_password_confirmation
                                "
                              />
                            </div>
                          </div>
                          <div class="tw-grid tw-gap-x-6 lg:tw-grid-cols-2">
                            <div class="">
                              <v-text-field
                                v-model="form.first_name"
                                :label="$t('forms.first_name')"
                                :rules="[rules.required]"
                                type="text"
                                outlined
                                validate-on-blur
                                required
                                rounded
                              />
                            </div>
                            <div>
                              <v-text-field
                                v-model="form.last_name"
                                :label="$t('forms.last_name')"
                                :rules="[rules.required]"
                                type="text"
                                outlined
                                validate-on-blur
                                required
                                rounded
                              />
                            </div>
                          </div>
                          <div class="tw-grid tw-gap-x-6 tw-gap-y-2 lg:tw-grid-cols-2">
                            <template v-for="default_field in default_fields">
                              <div
                                v-if="default_field.is_active"
                                :key="default_field.name"
                              >
                                <select-countries
                                  v-if="default_field.name === 'country_code'"
                                  v-model="form[default_field.name]"
                                  :label="default_field.label"
                                  :rules="
                                    default_field.is_required ? [rules.required] : []
                                  "
                                  outlined
                                  rounded
                                  validate-on-blur
                                />
                                <v-text-field
                                  v-else
                                  v-model="form[default_field.name]"
                                  :label="default_field.label"
                                  :name="default_field.name"
                                  :rules="
                                    default_field.is_required ? [rules.required] : []
                                  "
                                  type="text"
                                  outlined
                                  validate-on-blur
                                  rounded
                                />
                              </div>
                            </template>
                          </div>
                          <DynamicFields :fields="registry_form_submission" />
                        </div>
                      </v-container>
                    </v-card>
                    <template v-if="errorAlert">
                      <div class="tw-py-4">
                        <v-alert
                            v-model="errorAlert"
                            dismissible
                            color="red"
                            border="left"
                            elevation="2"
                            colored-border
                            icon="error"
                            transition="fade-transition"
                          >
                            {{ errorMsg }}
                          </v-alert>
                      </div>
                    </template>
                  </v-col>
                  <!-- ************ Details ************ -->
                  <v-col cols="12" sm="12" md="4">
                    <v-card class="mx-auto overflow-hidden tw-rounded-xl tw-shadow-md tw-p-6">
                      <template v-if="registry.is_multi_ticket">
                        <template v-if="ticket_select">
                          <div class="tw-mb-2">
                            <img :src="ticket_select.url_image_product" class="tw-w-auto tw-mx-auto tw-rounded-lg"/>
                          </div>
                          <span class="tw-text-base tw-text-gray-700 tw-font-bold tw-mb-3">{{ ticket_select.ticket_label_key }}</span>
                          <p class="tw-text-sm tw-text-gray-500 tw-leading-4" v-html="ticket_select.ticket_description" />
                          <v-divider />
                          <div class="tw-flex tw-items-center tw-justify-between tw-pt-2 tw-pb-4">
                            <span class="tw-font-bold tw-text-xl">Total:</span>
                            <span class="tw-font-bold tw-text-lg">{{ ticket_select.price_ticket }} {{ gatewayKey.currency }}</span>
                          </div>
                        </template>
                        <template v-else>
                          <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                            <v-icon class="tw-text-5xl py-6">category</v-icon>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <span class="tw-text-base tw-text-gray-700 tw-font-bold tw-mb-3">General</span>
                        <p class="tw-text-sm tw-text-gray-500 tw-leading-4 tw-font-light" v-html="registry.price_event_description" />
                        <v-divider />
                        <div class="tw-flex tw-items-center tw-justify-between tw-pt-2 tw-pb-4">
                          <span class="tw-font-bold tw-text-xl">Total:</span>
                          <span class="tw-font-bold tw-text-lg">{{ registry.price_event }} {{ gatewayKey.currency }}</span>
                        </div>
                      </template>
                      <!-- ************ Button and terms ************ -->
                      <p v-if="!processDataGateway" class="tw-flex tw-text-xs tw-leading-4 tw-m-0 tw-mb-2">
                        <v-checkbox
                          id="terms"
                          v-model="form.terms"
                          :rules="[rules.required]"
                          hide-details=""
                          class="tw-m-0"
                          dense
                        />
                        <label for="terms" class="tw-text-gray-500 tw-text-xs">
                          <p class="tw-m-0">
                           {{ $t('admin.app.paid_term') }}
                            <a :href="linkDoc" target="_blank">{{ $t('terms.term2') }}</a>.
                          </p>
                        </label>
                      </p>
                      
                      <AppBtn
                        v-if="!processDataGateway"
                        type="submit"
                        color="primary"
                        :loading="loading"
                        :style="colorBtn"
                        class="tw-rounded-md tw-shadow-none tw-text-sm"
                        block
                      >
                          {{ $t('admin.app.paid_button') }}
                      </AppBtn>

                      <PaymentComponent 
                        v-if="processDataGateway"
                        name="Culqi"
                        :data="payloadCulqi"
                        @on-success="register"
                      />            
                      <v-img v-if="!processDataGateway" :src="require('@/assets/credit-cards.png')" height="30" contain class="mt-4 d-flex" />
                    </v-card>
                  </v-col>
              </v-row>
            </div>
          </v-form>
        </section>

        <!-- ************************************** -->
        <!-- ~ Register default                     -->
        <!-- ************************************** -->
        <section v-if="!registry.has_payment" class="tw-relative tw-bg-slate-100 tw-p-0 md:tw-py-12">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            autocomplete="off"
            :disabled="loading"
            @submit.prevent="handleSubmit"
          >
            <div class="tw-mx-auto tw-max-w-lg tw-space-y-6 tw-px-0 sm:tw-px-4 md:tw-max-w-5xl lg:tw-px-8" :class="registry.has_payment ? 'md:tw-max-w-6xl' : 'md:tw-max-w-5xl'">
              <!-- ====== Form Register Default ====== -->
              <template v-if="!isActiveCustomForm">
                <v-card class="mx-auto overflow-hidden tw-rounded-xl tw-shadow-md">
                  <v-container class="tw-px-6 tw-py-5">
                    <h2 class="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                      {{ $t('pages.registry.register.main_form_title') }}
                    </h2>
                  </v-container>
                  <v-divider class="tw-border-gray-100"/>
                  <v-container class="tw-px-6 tw-py-4">
                    <div class="tw-my-4">
                      <div class="tw-grid lg:tw-grid-cols-3">
                        <div class="lg:tw-col-span-2">
                          <v-text-field
                            ref="email"
                            v-model="form.email"
                            prepend-inner-icon="alternate_email"
                            :label="$t('forms.email')"
                            type="email"
                            outlined
                            validate-on-blur
                            :rules="[rules.required, rules.email]"
                            rounded
                          />
                        </div>
                      </div>
                      <div
                        v-if="registry.has_password"
                        class="tw-grid tw-gap-6 lg:tw-grid-cols-2"
                      >
                        <div class="">
                          <v-text-field
                            v-model="form.password"
                            :label="$t('forms.password')"
                            :rules="[rules.required]"
                            outlined
                            :append-icon="
                              show_password ? 'visibility_off' : 'visibility'
                            "
                            :type="show_password ? 'text' : 'password'"
                            validate-on-blur
                            rounded
                            @click:append="show_password = !show_password"
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="form.password_confirmation"
                            :label="$t('forms.confirm_password')"
                            :append-icon="
                              show_password_confirmation
                                ? 'visibility_off'
                                : 'visibility'
                            "
                            :type="
                              show_password_confirmation ? 'text' : 'password'
                            "
                            :rules="[rules.required, passwordMatch]"
                            outlined
                            validate-on-blur
                            required
                            rounded
                            @click:append="
                              show_password_confirmation =
                                !show_password_confirmation
                            "
                          />
                        </div>
                      </div>
                      <div class="tw-grid tw-gap-6 lg:tw-grid-cols-2">
                        <div class="">
                          <v-text-field
                            v-model="form.first_name"
                            :label="$t('forms.first_name')"
                            :rules="[rules.required]"
                            type="text"
                            outlined
                            validate-on-blur
                            required
                            rounded
                          />
                        </div>
                        <div>
                          <v-text-field
                            v-model="form.last_name"
                            :label="$t('forms.last_name')"
                            :rules="[rules.required]"
                            type="text"
                            outlined
                            validate-on-blur
                            required
                            rounded
                          />
                        </div>
                      </div>
                      <div class="tw-grid tw-gap-x-6 tw-gap-y-2 lg:tw-grid-cols-2">
                        <template v-for="default_field in default_fields">
                          <div
                            v-if="default_field.is_active"
                            :key="default_field.name"
                          >
                            <select-countries
                              v-if="default_field.name === 'country_code'"
                              v-model="form[default_field.name]"
                              :label="default_field.label"
                              :rules="
                                default_field.is_required ? [rules.required] : []
                              "
                              outlined
                              rounded
                              validate-on-blur
                            />
                            <v-text-field
                              v-else
                              v-model="form[default_field.name]"
                              :label="default_field.label"
                              :name="default_field.name"
                              :rules="
                                default_field.is_required ? [rules.required] : []
                              "
                              type="text"
                              outlined
                              validate-on-blur
                              rounded
                            />
                          </div>
                        </template>
                      </div>
                      <DynamicFields :fields="registry_form_submission" />
                    </div>
                  </v-container>
                </v-card>
              </template>
              <!-- ====== Custom Form ====== -->
              <template v-if="isActiveCustomForm">
                <v-card class="tw-relative mx-auto sm:overflow-hidden md:tw-rounded-xl tw-shadow-sm">
                  <v-container class="tw-px-6 tw-py-5">
                    <h2 v-if="custom_form_submission.title" class="tw-text-2xl tw-font-semibold tw-leading-6 tw-text-gray-900">
                      {{ custom_form_submission.title }}
                    </h2>
                    <p v-if="custom_form_submission.description" class="tw-text-gray-500 tw-m-0 tw-mt-1">{{ custom_form_submission.description }}</p>
                  </v-container>
                  <v-divider class="tw-border-gray-100"/>
                  <v-container class="tw-px-6 tw-py-4">
                    <div class="tw-my-4">
                      <DynamicFields v-if="custom_form_submission_fields" :fields="custom_form_submission_fields" />
                    </div>
                  </v-container>
                </v-card>
              </template>

              <!-- ====== Terminos y condiciones ====== -->
              <v-card class="mx-auto sm:overflow-hidden md:tw-rounded-xl tw-shadow-sm">
                  <v-container class="tw-px-4 tw-py-6 sm:tw-p-6">
                    <div>
                      <h2 class="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">{{ $t('forms.terms') }}</h2>
                      <p v-if="current_form.terms_and_conditions_company" class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                        {{ current_form.terms_and_conditions_company }}
                      </p>
                      <p v-else class="tw-mt-1 tw-mb-0 tw-text-sm tw-text-gray-500">
                        {{ $t('pages.event.terms') }}
                      </p>
                    </div>
                    <div class="tw-mt-6 tw-space-y-2">
                      <div class="tw-grid lg:tw-grid-cols-3">
                        <div class="tw-flex tw-justify-start lg:tw-col-span-2">
                          <v-checkbox
                            id="terms"
                            v-model="form.terms"
                            :rules="[rules.required]"
                            hide-details=""
                            class="tw-mt-0"
                          />
                          <label for="terms" class="tw-text-gray-500 tw-text-sm">
                            <span>
                              {{ $t('terms.term1') }}
                              <a :href="linkDoc" target="_blank">{{
                                $t('terms.term2')
                              }}</a
                              >.
                            </span>
                            {{ $t('pages.event.terms_accept') }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </v-container>
              </v-card>

              <!-- ====== Button Action ====== -->
              <div class="tw-pb-6 tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-justify-between sm:tw-flex-row">
                <transition name="fade-transition" mode="out-in">
                  <v-row v-if="errorAlert">
                    <v-col cols="12">
                      <v-alert
                        v-model="errorAlert"
                        dismissible
                        color="red"
                        border="left"
                        elevation="2"
                        colored-border
                        icon="error"
                        transition="fade-transition"
                      >
                        {{ errorMsg }}</v-alert
                      >
                    </v-col>
                  </v-row>
                </transition>
                <v-spacer />
                <AppBtn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  icon="check"
                  :style="colorBtn"
                >
                  {{ $t('app.register') }}
                </AppBtn>
              </div>
            </div>
          </v-form>
        </section>

        <!-- ************************************** -->
        <!-- ~ Support Email and WhatsApp           -->
        <!-- ************************************** -->
        <section 
          v-if="registry.has_support"
          class="tw-bg-slate-100 tw-pt-10 tw-pb-6"
        >
          <div class="tw-mx-auto tw-max-w-sm">
            <RegistrySupportText
              class="tw-mb-0 tw-pt-4 tw-pb-4 tw-text-center tw-text-sm tw-leading-5 tw-text-gray-700"
            />
          </div>
        </section>
      </main>
    </RegistryLayout>
  </renderless-layout>
</template>

<script>
import RegistryLayout from './RegistryLayout.vue'
import SelectCountries from '@/components/FormInputs/SelectCountries.vue'
import RegistrySupportText from './RegistrySupportText.vue'
import RegistryHeader from './RegistryHeader.vue'
import { mapGetters, mapState } from 'vuex'
import { rules } from '@/utils/rules'
import { matchCols } from '@/utils/formCols'
import { RegistryStateKeys } from '@/enums/RegistryStateKeys'
import { isPastDate } from '@/utils/dateHelpers'
import AppBtn from '@/components/App/AppBtn.vue'
import { addModelText, onlyNumber } from '@/utils/Settings'
// --------------------- Payment --------------------- //
import DynamicFields from '@/components/DynamicFields.vue'
import PaymentComponent from '@/components/PaymentComponent.vue'

export default {
  name: 'BizzDomeUiRegister',
  components: {
    RegistryLayout,
    SelectCountries,
    AppBtn,
    RegistryHeader,
    RegistrySupportText,
    // Payment
    DynamicFields,
    PaymentComponent,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    matchCols,
    loading: false,
    show_password: false,
    show_password_confirmation: false,
    errorAlert: '',
    errorMsg: '',
    rules,
    valid: true,
    form: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      password_confirmation: '',
      company: '',
      title: '',
      country_code: '',
      address: '',
      city: '',
      phone_number: '',
    },
    registry_form_submission: [],
    default_fields: [],
    onlyNumber,
    // ---------------- New Data Payment ---------------- //
    ticket_select: 0,
    processDataGateway: false,
    payloadCulqi: {},
    // ----------------  Custom Form ---------------- //
    custom_form_submission: [],
    custom_form_submission_fields: [],
  }),
  head() {
    return {
      title: {
        inner: this.registry.label,
      },
      style: [
        {
          type: 'text/css',
          inner: this.current_landing.custom_css,
        },
      ],
    }
  },
  computed: {
    colorBtn() {
      const bg = this.current_landing.ui_color ? this.current_landing.ui_color : '#3565af'
      const hex = this.current_landing.ui_contrast ? this.current_landing.ui_contrast : '#3565af'
      return {
        'background-color': `${bg} !important`,
        'color': `${hex}`,
      }
    },
    bgUrl() {
      return this.current_form.header_url
        ? this.current_form.header_url
        : require('@/assets/bg_login_bizzdome.jpg')
    },
    linkDoc() {
      return this.current_form.terms_and_conditions_link
        ? this.current_form.terms_and_conditions_link
        : this.$i18n.t('terms.term_link')
    },
    wa_msg() {
      return this.$i18n.locale === 'en' ? 'Hi%20!' : 'Hola%20!'
    },
    wa_link() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return `https://api.whatsapp.com/send?phone=51994225574&text=${this.wa_msg}`
      }
      return `https://web.whatsapp.com/send?phone=51994225574&text=${this.wa_msg}`
    },
    toRegister() {
      return {
        name: 'registry.register',
        params: { slug: this.slug },
      }
    },
    toLogin() {
      return {
        name: 'registry.login',
        params: { slug: this.slug },
      }
    },
    canRegister() {
      return !this.registry.is_registration_disabled
    },
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
      registry_status: (state) => state.registry.show.registry_status,
      roomLink: (state) => state.registry.toPath.link,
    }),
    current_landing() {
      const landing = this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
      if (!landing) {
        return {}
      }
      return landing
    },
    hasPayment() {
      return this.registry.has_payment
    },
    formId() {
      return this.$route.params.form_id
    },
    isActiveCustomForm() {
      return !!this.formId
    },
    formData() {
      return this.getForm(this.formId)
    },
    ...mapGetters('registry',['getForm']),
    // **************************************
    // ~ Feat: Gateway
    // **************************************
    gatewayKey() {
      return this.registry.gateway.credentials.reduce((acc, item) => {
          acc[item.key] = item.value
          return acc
      }, {})
    },
  },
  watch: {
    current_language_key: {
      handler() {
        this.$nextTick(() => {
          this.initForm()
        })
      },
    },
    formId(newFormId) {
      if (newFormId) {
        this.initCustomForm()
      }
    },
  },
  mounted() {
    // **************************************
    // ~ Culqi Script
    // **************************************
    if (this.registry.has_payment) {
      this.scriptCulqi()
    }
    // **************************************
    // ~ Custom Forms
    // **************************************
    if (this.isActiveCustomForm) {
      this.initCustomForm()
    }
    this.initForm()
    if (!this.canRegister) {
      this.$router.push({
        name: 'registry.home',
        params: { slug: this.slug },
      })
    }
    if (!this.registry.end_date_time) {
      return
    }
    if (isPastDate(this.registry.end_date_time)) {
      this.$router.push({
        name: 'registry.thank-you',
        params: { slug: this.slug },
      })
    }
  },
  beforeDestroy() {
    const scriptCulqi = document.getElementById('culqi-checkout-script')
    if (scriptCulqi) {
      scriptCulqi.remove()
    }
  },
  methods: {
    handleSubmit() {
      // Validar datos del formulario
      if (!this.$refs.form.validate()) return

      // Validar si esta activo formularios personalizados
      if (this.isActiveCustomForm) {
        return this.submitCustomForm()
      } 
      
      // Validar si es formulario con pasarela de pago
      if (this.registry.has_payment) {
        return this.handleProcessPayment()
      } 

      // solo registrar
      return this.register()
    },
    initCustomForm() {
      const current_custom_form = JSON.parse(JSON.stringify(this.formData))
      this.custom_form_submission = current_custom_form
      this.custom_form_submission_fields = current_custom_form.fields
    },
    initForm() {
      const current_form = JSON.parse(JSON.stringify(this.current_form))
      console.log(current_form)
      this.registry_form_submission = current_form.fields

      this.default_fields = current_form.default_fields.filter(
        (row) => row.name !== 'email',
      )
    },
    passwordMatch() {
      return this.form.password === this.form.password_confirmation
        ? true
        : 'Contraseña no es igual'
    },
    register() {
      /**
       * key -> value
       * custom -> [ ...fields ]
       */
      // --------------- Form Validate --------------- //
      if (!this.$refs.form.validate()) {
        return
      }
      // --------------- Payload Register --------------- //
      this.errorAlert = false
      const payload = {
        uuid: this.current_form.uuid,
        body: {
          ...this.form,
          registry_form_submission: addModelText(this.registry_form_submission),
        },
      }
      // --------------- Actions --------------- //
      this.loading = true
      this.registerSingle(payload)
    },
    registerSingle(payload) {
      return this.$store
        .dispatch('session/registerEvent', payload)
        .then(() => {
          if (this.registry_status == RegistryStateKeys.open) {
            const link = this.roomLink ? this.roomLink : '/event/'+this.registry.slug
            this.$router.push(link)
            return
          }
          this.$router.push({
            name: 'registry.confirmed',
            params: { slug: this.slug },
          })
          return
        })
        .catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
        })
        .finally(() => {
          this.loading = false
        })
    },
    submitCustomForm() {
      const payload = {
          uuid: this.current_form.uuid,
          body: {
            custom_form_id: this.formId,
            // registry_id: this.registry.id,
            form_submission: addModelText(this.custom_form_submission_fields),
            language: this.current_language_key,
          },
      }
      this.$store.dispatch('registry/submissionCustomForm', payload)
        .then(() => {
          this.$router.push({
            name: 'registry.confirmed',
            params: { slug: this.slug },
          })
        })
        .finally(() => {
          this.clearCustomFormFields()
        })
    },
    clearCustomFormFields() {
      this.custom_form_submission_fields.forEach(field => {
        switch (field.field_type) {
          case 'input':
          case 'textarea':
          case 'select':
            field.model = ''
            break
          case 'checkbox':
            field.model_multiple = []
            break
          case 'radio':
            field.model = null
            break
          case 'upload':
            field.model = null
            break
          default:
            field.model = ''
        }
      })
    },
    goToHome() {
      this.$router.push({
        name: 'registry.home',
        params: { slug: this.slug },
      })
    },
    goToRegister() {
      this.$router.push({
        name: 'registry.register',
        params: { slug: this.slug },
      })
    },
    goToLogin() {
      this.$router.push({
        name: 'registry.login',
        params: { slug: this.slug },
      })
    },
    // **************************************
    // ~ Feat: Gateways
    // **************************************
    async scriptCulqi() {
      return new Promise((resolve, reject) => {
        if (window.CulqiCheckout) {
          console.log('✅ Culqi ya está cargado.')
          resolve()
          return
        }

        const script = document.createElement('script')
        script.src = 'https://js.culqi.com/checkout-js'
        script.id = 'culqi-checkout-script'
        script.async = true
  
        script.onload = () => {
          console.log('✅ Script de Culqi cargado correctamente.')
          resolve()
        }

        script.onerror = () => {
          reject(new Error('❌ Error al cargar el script de Culqi.'))
        }

        document.head.appendChild(script)
      })
    },
    handleProcessPayment() {
      this.loading = true

      this.$store.dispatch('gateways/checkAttendee', {
        uuid: this.current_form.uuid,
        email: this.form.email,
      }).then(({data}) => {
        if (data.email === 'no_registered') {
          // Crear Payload para enviar al component Payment
          this.processPayloadCulqi()
          this.processDataGateway = true
        }
      }).catch((err) => {
          this.errorAlert = true
          this.errorMsg = err
          this.loading = false
      })
    },
    processPayloadCulqi() {
        const publicKey = this.gatewayKey.public_key

        const details = this.registry.is_multi_ticket
          ? {
              title: this.ticket_select.ticket_label_key,
              amount: this.ticket_select.price_ticket * 100,
            }
          : {
              title: this.registry.label,
              amount: this.registry.price_event * 100,
            }

        const settings = {
          ...details,
          currency: this.gatewayKey.currency,
        }

        const options = {
          lang: 'auto',
          installments: true,
          modal: true,
          container: '#culqi-container',
          paymentMethods: {
            tarjeta: true,
            yape: false,
            billetera: false,
            bancaMovil: false,
            agente: false,
            cuotealo: false,
          },
        }

        const client = {
          email: this.form.email,
        }

        const appearance = {
          theme: 'default',
          hiddenCulqiLogo: false,
          hiddenToolBarAmount: true,
          logo: this.current_landing.logo_url_lg,
        }

        const config = {
          settings,
          client,
          options,
          appearance,
        }

        const userRegister = {
          name: `${this.form.first_name} ${this.form.last_name}`,
          email: this.form.email,
        }

        this.payloadCulqi = {
          publicKey,
          config,
          userRegister,
        }
    },
  },
}
</script>

<style lang="scss">
.bz-card-element {
  border: 1px solid #aaaaaa;
  padding: 1.2rem;
  border-radius: 5rem;
}
.bz-powered-by-stripe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: .5rem;
  padding-bottom: .5rem;
  img {
    height: 1rem;
    margin-left: .3rem;
  }
}
</style>