<template>
    <renderless-layout>
        <v-container id="gateway_edit_page" fluid class="tw-p-0">
            <StickyHeader :nav="nav">
                <template #actions>
                    <div class="tw-flex tw-items-center tw-space-x-3">
                        <AppBtn color="transparent-dark" icon="trash_bin" @click="deleteForm">{{ $t('app.delete') }}</AppBtn>
                        <AppBtn color="white" icon="check" @click="updateGateway">{{ $t('app.update') }}</AppBtn>
                    </div>
                </template>
            </StickyHeader>
            <transition name="fade-transition" mode="out-in">
                <template v-if="showLoading">
                    <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
                        <v-progress-circular
                            :size="70"
                            :width="7"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </template>
                <template v-else>
                    <template v-if="!showLoading && gateway">
                        <FormBase 
                            ref="gatewayform"
                            v-model="rowGateway"
                        />
                    </template>
                </template>
            </transition>
            <AppConfirmDialog ref="deleteModal" />
        </v-container>
    </renderless-layout>
</template>
<script>
import StickyHeader from '@/components/StickyHeader'
import AppBtn from '@/components/App/AppBtn.vue'
import FormBase from '@/views/Admin/Registries/Gateways/FormBase.vue'
import { mapState } from 'vuex'
import AppConfirmDialog from '@/components/App/AppConfirmDialog.vue'
import { GatewayBase, GatewayBaseHydrate } from '@/models/Gateway'

export default {
    name: 'GatewaysEdit',
    components: {
        AppBtn,
        FormBase,
        StickyHeader,
        AppConfirmDialog,
    },
    props: {
        id: {
            type: [Number, String],
            default: 0,
        },
    },
    data: () => ({
        rowGateway: GatewayBase({}),
    }),
    computed: {
        ...mapState({
            registry: (state) => state.registries.show.data,
            gateway: (state) => state.gateways.show.data,
            showLoading: (state) => state.gateways.show.loading,
        }),
        nav() {
            return [
                {
                    name: this.$t('app.event'),
                    route: { name: 'admin.registries.index' },
                },
                {
                    name: this.registry.label,
                    route: {
                        name: 'admin.registries.show.home',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('admin.home.labels.gateways'),
                    route: {
                        name: 'admin.registries.gateways.index',
                        params: { id: this.registry.id },
                    },
                },
                {
                    name: this.$t('app.edit'),
                    route: {},
                },
            ]
        },
    },
    mounted() {
        this.loadGateway()
    },
    methods: {
        async loadGateway() {
            try {
                await this.$store.dispatch('gateways/show', {
                    id: this.id,
                    registry_id: this.registry.id,
                })
                .then((res) => {
                    this.rowGateway = GatewayBaseHydrate({
                        ...res,
                    })
                })
            } catch (error) {
                console.error('Error loading gateway', error)
            }
        },
        updateGateway() {
            this.$refs.gatewayform.submitUpdate()
        },
        async deleteForm() {
            const has_accepted = await this.$refs.deleteModal.open(
                'Eliminar',
                'Estas seguro de eliminar esta pasarela',
                { color: 'red' },
            )

            if (has_accepted) {
                this.$store
                    .dispatch('gateways/destroy', { 
                        id: this.id,
                        registry_id: this.registry.id,
                     })
                    .then(() => {
                        this.$router.push({ name: 'admin.registries.gateways.index' })
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        },
    },
}
</script>