<template>
  <renderless-layout>
    <v-container fluid class="tw-p-0">
      <sticky-header :nav="nav">
        <template #actions>
          <div class="tw-flex tw-items-center tw-space-x-3">
            <LanguageSelect
              v-model="current_config_language_key"
              :label="$t('admin.app.lang_version')"
              dark
            />
            <AppBtn
              color="primary-dark"
              icon="g-next"
              g-next="Eye"
              @click="preview"
            >
              {{ $t('app.preview') }}
            </AppBtn>
            <AppBtn color="white" icon="check" @click="updateForms">
              {{ $t('app.save') }}
            </AppBtn>
          </div>
        </template>
      </sticky-header>
      <v-row class="tw-m-0 tw-p-0">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="11"
          xl="9"
          class="mx-auto tw-max-w-5xl"
        >
          <!-- class="tw-container mx-auto" -->
          <v-container fluid class="tw-space-y-6">
            <v-row class="">
              <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                <form-description>
                  <template #title>
                    {{ $t('app.title_description') }}
                  </template>
                  <template #copy>
                    {{ $t('app.description_area') }}
                  </template>
                </form-description>
              </v-col>

              <v-col cols="12" md="9" class="mx-auto pa-5">
                <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                  <v-container class="pa-5">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-text-field
                          v-model="label"
                          validate-on-blur
                          type="text"
                          rounded
                          color="primary darken-1"
                          :label="$t('app.title')"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                          v-model="description"
                          validate-on-blur
                          type="text"
                          rounded
                          auto-grow
                          color="primary darken-1"
                          :label="$t('app.description')"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="tw-pb-0">
                        <dropzone-preview
                          v-model="header_url"
                          :width="1920"
                          :bypass="'no-check'"
                          :height="600"
                          media-group="register"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                <form-description>
                  <template #title> {{ $t('forms.terms') }} </template>
                  <template #copy>
                    {{ $t('app.default_terms') }}
                  </template>
                </form-description>
              </v-col>

              <v-col cols="12" md="9" class="mx-auto pa-5">
                <v-card class="mx-auto tw-rounded-3xl tw-shadow-md">
                  <v-container class="pa-5">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                          v-model="terms_and_conditions_company"
                          validate-on-blur
                          type="text"
                          rounded
                          auto-grow
                          :label="$t('forms.terms')"
                          outlined
                        />
                      </v-col>
                      <v-col cols="12" class="">
                        <div class="">
                          <div class="">
                            <h3
                              class="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-700"
                            >
                              {{ $t('app.file_terms') }}
                            </h3>
                            <DropzoneOverlayPreview
                              v-model="terms_and_conditions_link"
                              :bypass="'no-check'"
                              media-group="content-library-download-file"
                            />
                          </div>
                          <div class="tw-mt-3 tw-flex tw-justify-end">
                            <p
                              class="tw-mb-0 tw-max-w-2xl tw-text-sm tw-text-gray-500"
                            >
                              *{{ $t('app.doc_if') }}
                              <a :href="linkDoc" target="_blank">
                                <span class="tw-font-bold tw-underline">
                                  {{ $t('app.doc') }}
                                </span>
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <!-- Políticas de cambio y/o devoluciones -->
                      <v-col cols="12" class="">
                        <div class="">
                          <div class="">
                            <h3
                              class="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-700"
                            >
                              {{ $t('app.file_policies') }}
                            </h3>
                            <DropzoneOverlayPreview
                              v-model="exchange_refund_policies_link"
                              :bypass="'no-check'"
                              media-group="content-library-download-file"
                            />
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="12" md="3" class="px-5 pt-5 mx-auto">
                <form-description>
                  <template #title> {{ $t('app.default_fields') }} </template>
                  <template #copy>
                    {{ $t('app.default_fields_description') }}
                  </template>
                </form-description>
              </v-col>

              <v-col cols="12" md="9" class="mx-auto pa-5">
                <v-card
                  class="mx-auto tw-overflow-hidden tw-rounded-3xl tw-shadow-md"
                >
                  <v-container class="tw-p-0">
                    <div class="tw-shadow">
                      <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                        <li v-for="field in default_fields" :key="field.name">
                          <div
                            class="tw-flex tw-items-center tw-px-4 tw-py-3 sm:tw-py-5 sm:tw-px-6"
                          >
                            <div
                              class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                            >
                              <div class="tw-w-full">
                                <div class="tw-flex tw-justify-between">
                                  <label
                                    class="tw-block tw-pb-2 tw-font-semibold tw-text-gray-600 sm:tw-text-lg"
                                  >
                                    {{ field.label }}
                                  </label>
                                  <div class="tw-w-64">
                                    <v-text-field
                                      v-model="field.label"
                                      dense
                                      outlined
                                      :label="$t('app.public_name')"
                                      rounded
                                      hide-details=""
                                    />
                                  </div>
                                </div>
                                <div class="tw-grid tw-w-full tw-grid-cols-3">
                                  <div>
                                    <v-switch
                                      v-if="field.name !== 'email'"
                                      v-model="field.is_active"
                                      :label="$t('app.active')"
                                      color="primary"
                                      hide-details=""
                                    />
                                  </div>
                                  <div>
                                    <v-switch
                                      v-if="field.name !== 'email'"
                                      v-model="field.is_required"
                                      :disabled="!field.is_active"
                                      :label="$t('app.required')"
                                      color="primary"
                                      hide-details=""
                                    />
                                  </div>
                                  <div>
                                    <v-switch
                                      v-model="field.is_public"
                                      :disabled="!field.is_active"
                                      :label="$t('app.on_card')"
                                      color="primary"
                                      hide-details=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-container fluid class="tw-p-0">
                  <v-card
                    flat
                    color="transparent"
                    class="tw-mb-6 tw-overflow-hidden tw-rounded-3xl tw-border-transparent tw-bg-transparent tw-shadow-md"
                  >
                    <v-container
                      class="tw-bg-gradient-to-r tw-from-bizzdome-terciary tw-to-bizzdome-light-brown tw-p-0"
                      fluid
                    >
                      <div class="tw-px-4 tw-py-5 sm:tw-px-6">
                        <h2
                          class="tw-title-font tw-mb-2 tw-text-xl tw-font-medium tw-text-gray-50 sm:tw-text-2xl"
                        >
                          {{ $t('app.custom_fields') }}
                        </h2>
                        <p
                          class="tw-mt-1 tw-max-w-xl tw-text-sm tw-text-gray-100"
                        >
                          {{ $t('app.fields_description') }}
                        </p>
                        <div class="tw-flex tw-justify-start tw-space-x-2">
                          <v-btn
                            color="white"
                            rounded
                            class="tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
                            @click="addField"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                            {{ $t('app.add_field') }}
                          </v-btn>
                        </div>
                      </div>
                    </v-container>
                    <v-container class="tw-p-0">
                      <template v-if="fields.length > 0">
                        <draggable
                          v-model="fields"
                          tag="div"
                          class="tw-overflow-hidden tw-bg-white tw-shadow"
                          :move="onMove"
                          @start="isDragging = true"
                          @end="isDragging = false"
                        >
                          <transition-group
                            type="transition"
                            :name="'flip-list'"
                            tag="ul"
                            class="tw-divide-y tw-divide-gray-200 tw-pl-0"
                          >
                            <li v-for="row in fields" :key="row.uid">
                              <div
                                class="tw-block tw-cursor-move hover:tw-bg-gray-50"
                              >
                                <div
                                  class="tw-flex tw-items-center tw-px-4 tw-py-4 sm:tw-px-6"
                                >
                                  <div
                                    class="tw-min-w-0 tw-flex-1 sm:tw-flex sm:tw-items-center sm:tw-justify-between"
                                  >
                                    <div class="tw-truncate">
                                      <div
                                        class="tw-flex tw-text-sm lg:tw-text-lg"
                                      >
                                        <p
                                          class="tw-mb-0 tw-truncate tw-font-medium tw-text-indigo-600"
                                        >
                                          {{ row.label }}
                                        </p>
                                      </div>
                                      <div class="tw-mt-2 tw-flex">
                                        <div
                                          class="tw-flex tw-items-center tw-text-sm tw-text-gray-500"
                                        >
                                          <v-icon class="tw-mr-1.5">{{
                                            look_up_fields[row.field_type].icon
                                          }}</v-icon>
                                          <p class="tw-mb-0">
                                            {{
                                              look_up_fields[row.field_type]
                                                .text
                                            }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="tw-mt-4 tw-flex-shrink-0 sm:tw-mt-0 sm:tw-ml-5"
                                    >
                                      <div
                                        class="tw-flex tw-items-center tw-space-x-2"
                                      >
                                        <v-btn
                                          text
                                          rounded
                                          class="tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-300"
                                          @click="deleteItem(row)"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M6 18L18 6M6 6l12 12"
                                            />
                                          </svg>
                                          {{ $t('app.delete') }}
                                        </v-btn>
                                        <v-btn
                                          color="primary"
                                          rounded
                                          dark
                                          class="tw-bg-transparent tw-px-8 tw-font-bold tw-normal-case focus:tw-outline-none focus:tw-ring focus:tw-ring-gray-50"
                                          @click="updateItem(row)"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            class="tw-mr-2 tw--ml-1 tw-h-6 tw-w-6"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              stroke-width="2"
                                              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                            />
                                          </svg>
                                          {{ $t('app.edit') }}
                                        </v-btn>
                                        <!-- Heroicon name: solid/chevron-right -->
                                      </div>
                                    </div>
                                  </div>
                                  <div class="tw-ml-5 tw-flex-shrink-0">
                                    <!-- Heroicon name: solid/chevron-right -->

                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      class="tw-h-5 tw-w-5 tw-text-gray-400"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </transition-group>
                        </draggable>
                      </template>
                      <div
                        v-else
                        class="tw-overflow-hidden tw-bg-white tw-shadow"
                      >
                        <ul class="tw-divide-y tw-divide-gray-200 tw-pl-0">
                          <li>
                            <EmptyState>
                              {{ $t('$vuetify.noDataText') }}
                            </EmptyState>
                          </li>
                        </ul>
                      </div>
                    </v-container>
                  </v-card>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <RegistriesFormInputModal
        v-if="form_input_modal"
        v-model="form_input_modal"
        :form-input="selectedField"
        @updated-form-input="updateFormItem"
      />
    </v-container>
  </renderless-layout>
</template>

<script>
import DropzoneOverlayPreview from '@/components/DropzoneOverlayPreview.vue'

import EmptyState from '@/components/EmptyState.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import AppBtn from '@/components/App/AppBtn.vue'
import { mapState, mapGetters } from 'vuex'
import { mapFields, mapNestedFields } from '@/helpers'
import RegistriesFormInputModal from '@/components/RegistriesModals/RegistriesFormInputModal.vue'
import FormDescription from '@/components/FormDescription.vue'
import DropzonePreview from '@/components/DropzonePreview.vue'
import draggable from 'vuedraggable'
import { LookUpFields, RegistryRegisterInputBase } from '@/models/RegistryForm'
export default {
  components: {
    AppBtn,
    DropzoneOverlayPreview,
    LanguageSelect,
    DropzonePreview,
    RegistriesFormInputModal,
    StickyHeader,
    EmptyState,
    FormDescription,
    draggable,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data: () => ({
    isDragging: false,
    look_up_fields: { ...LookUpFields },
    selectedField: {},
    // fields: [],
    form_input_modal: false,
  }),
  computed: {
    nav() {
      return [
        {
          name: this.$t('app.event'),
          route: { name: 'admin.registries.index' },
        },
        {
          name: this.show.label,
          route: {
            name: 'admin.registries.show.home',
            params: { id: this.show.id },
          },
        },
        {
          name: this.$t('app.forms'),
          route: {},
        },
      ]
    },
    ...mapFields({
      fields: ['current_config_language_key'],
      base: ['registries', 'forms', 'update', 'data'],
      mutation: 'registries/setFormsUpdateWrapper',
    }),
    linkDoc() {
      return this.$i18n.t('terms.term_link', this.current_config_language_key)
    },
    ...mapNestedFields({
      fields: [
        'label',
        'terms_and_conditions_link',
        'exchange_refund_policies_link',
        'description',
        'default_fields',
        'fields',
        'terms_and_conditions_company',
        'header_url',
      ],
      base: ['registries', 'forms', 'update', 'data'],
      mutation: 'registries/setFormsUpdateForm',
      nested_key: 'current_config_language_key',
    }),
    ...mapGetters({
      languages: ['constants/languages'],
    }),
    ...mapState({
      show: (state) => state.registries.show.data,
    }),
    current_slug() {
      return this.show.slug
    },
  },
  beforeDestroy() {
    console.log('destroyed')
    this.$store.dispatch('registries/initFormsUpdate')
  },
  methods: {
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
    },
    updateForms() {
      this.$store.dispatch('registries/updateForms').then(() =>
        this.$store.dispatch('registries/show', {
          id: this.id,
          appLoading: true,
        }),
      )
    },
    addField() {
      this.selectedField = RegistryRegisterInputBase({})
      this.form_input_modal = true
    },
    updateFormItem(field) {
      if (this.fields.find((r) => r.uid === field.uid)) {
        this.fields = this.fields.map((row) =>
          row.uid === field.uid ? field : row,
        )
        return
      }
      this.fields = [...this.fields, field]
    },
    updateItem(field) {
      this.selectedField = field
      this.form_input_modal = true
    },
    deleteItem(field) {
      this.fields = this.fields.filter((n) => n.uid !== field.uid)
    },
    preview() {
      this.$router.push({
        name: 'registry.register',
        params: { slug: this.current_slug },
      })
    },
  },
}
</script>

<style></style>
