<template>
  <div>
    <div
      v-if="registry.has_support"
      class="tw-py-2 tw-text-sm"
      :style="supportFooter.color ? { backgroundColor: applyOpacity(supportFooter.color, 0.2) } : {}"
    >
      <div class="tw-max-w-4xl tw-mx-auto tw-text-center tw-grid tw-grid-cols-1 lg:tw-flex tw-items-center tw-justify-between">
        <span v-if="supportFooter.support_direction" class="bz-comercio-direction">{{ supportFooter.support_direction }}</span>
        <span v-if="supportFooter.support_phone_number" class="bz-comercio-phone">+{{ supportFooter.support_phone_number }}</span>
        <span v-if="supportFooter.support_email" class="bz-comercio-email">{{ supportFooter.support_email }}</span>
      </div>
    </div>
    <footer class="tw-bg-landing-primary tw-text-center py-4 tw-text-white">
      <!-- Redes sociales -->
      <div v-if="section.length !== 0" class="bz_social_media tw-pt-2 tw-pb-6 tw-space-x-4">
        <span class="bz_social_media_title">{{ section.title }}</span>
        <a v-for="logo in processedSection" :key="logo.uid" :href="logo.link" target="_blank">
          <img :src="logo.logo_url" class="bz-icon-social" :alt="logo.uid" />
        </a>
      </div>

      <!-- Enlaces legales -->
      <div class="tw-text-white tw-space-x-4 tw-text-sm">
        <a :href="terms" target="_blank" class="tw-text-white hover:tw-underline">Términos y Condiciones</a>
        <span>|</span>
        <a class="tw-text-white hover:tw-underline" @click="toBook">Libro de Reclamaciones</a>
        <span v-if="registry.has_payment">|</span>
        <a v-if="registry.has_payment" :href="policies" target="_blank" class="tw-text-white hover:tw-underline">Políticas de cambio y/o devoluciones</a>
      </div>

      <!-- Copyright -->
      <div class="tw-text-gray-100/60 tw-text-center tw-mt-5 tw-text-sm">
        &copy; {{ year }} BIZZEVENTS, All rights reserved.
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    section: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    processedSection() {
      return this.section?.logos
    },
    supportFooter() {
      const {  has_support, support_user_id, support_email, support_phone_number, support_direction } = this.registry
      return {
        has_support, 
        support_user_id, 
        support_email, 
        support_phone_number, 
        support_direction,
        color: this.current_landing.ui_color,
      }
    },
    terms() {
      return this.current_form.terms_and_conditions_link
        ? this.current_form.terms_and_conditions_link
        : this.$i18n.t('terms.term_link')
    },
    policies() {
      return this.current_form.exchange_refund_policies_link
        ? this.current_form.exchange_refund_policies_link
        : ''
    },
    current_landing() {
      return this.registry.landings.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    current_form() {
      return this.registry.forms.find(
        (row) => row.language.name === this.current_language_key,
      )
    },
    ...mapState({
      current_language_key: (state) => state.registry.show.current_language_key,
      registry: (state) => state.registry.show.data,
    }),
  },
  methods: {
    toBook() {
      this.$router.push({
        name: 'registry.complaints-book',
        params: { slug: this.registry.slug },
      })
    },
    applyOpacity(hex, opacity) {
      // Elimina el '#' si existe
      hex = hex.replace('#', '')
      // Soporta colores de 3 o 6 dígitos
      if (hex.length === 3) {
        hex = hex.split('').map(c => c + c).join('')
      }
      const bigint = parseInt(hex, 16)
      const r = (bigint >> 16) & 255
      const g = (bigint >> 8) & 255
      const b = bigint & 255
      return `rgba(${r}, ${g}, ${b}, ${opacity})`
    },
  },
}
</script>

<style>
.bz-icon-social {
  width: 1.8rem;
  height: auto;
  margin: 0 4px 0 4px;
}

.bz_social_media {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bz_social_media_title {
  margin-right: 0.5rem;
  font-size: 14px;
}
</style>
